import { useLocation } from 'react-router-dom';
import * as analytics from '../utils/analytics';
import * as React from 'react';
import { AuthContext } from '@/context/auth-context';
import useBox from '@/hooks/use-box';
import GAME_DATA from '@/data/app-2/game';

const PageLoaded = () => {
  const location = useLocation();
  const currentBox = useBox(GAME_DATA.id);
  const { user } = React.useContext(AuthContext);
  const lastLocationKeyRef = React.useRef('');

  React.useEffect(() => {
    if (currentBox.id && user && location.key !== lastLocationKeyRef.current) {
      analytics.pageLoaded({
        currentBoxNumber: currentBox.id,
        userId: user._id,
        userEmail: user.email,
      });

      lastLocationKeyRef.current = location.key;
    }
  }, [location, user, currentBox.id]);

  return null;
};

export default PageLoaded;
