import * as React from 'react';
import queryKeys from '@/constants/query-keys';
import { AuthContext } from '@/context/auth-context';
import { useQuery } from '@tanstack/react-query';
import { progressService } from '@/services/progress';

function useProgress(appId: string) {
  const { user, loggedIn } = React.useContext(AuthContext);

  const queryResult = useQuery({
    queryKey: queryKeys.progress(appId, user?._id),
    queryFn: () => progressService.get(appId),
    enabled: loggedIn && !!user,
    throwOnError: true,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  return { ...queryResult, progress: queryResult.data };
}

export default useProgress;
