import { Progress } from '@/types/progress';
import { queryClient } from './query-client';
import queryKeys from '@/constants/query-keys';
import deepMerge from './deep-merge';
import { localStorageKeys } from '@/constants/local-storage';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

function updateProgressQuery(appId: string, update: DeepPartial<Progress>) {
  localStorage.getItem('token');

  queryClient.setQueryData<Progress>(
    queryKeys.progress(appId, localStorage.getItem(localStorageKeys.userId)),
    (data) => {
      if (!data) return data;

      // add `at` field to the update every time there's an `status` key recursively
      const addAtField = (obj: any) => {
        if (typeof obj !== 'object') return obj;

        for (const key in obj) {
          if (key === 'status') {
            obj.at = new Date().toISOString();
          } else if (typeof obj[key] === 'object') {
            obj[key] = addAtField(obj[key]);
          }
        }

        return obj;
      };

      const result = deepMerge(data, addAtField(update));

      return result;
    }
  );
}

export default updateProgressQuery;
